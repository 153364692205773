import HttpService from '@src/utils/HttpService';
import IMessage from '@src/interfaces/IMessage';
import { objToCamel } from '@src/utils/helpers';
import IBotMessageResponse from '@src/interfaces/IBotMessageResponse';
import IThread from '@src/interfaces/IThread';

export const createMessage = async (thread: IThread, content: string) => {
    const response = { data: {} as IBotMessageResponse, success: false, message: '' };

    try {
        const endPoint = '/messages/create  ';
        const formatApiData = { threadId: thread.threadId, botId: thread.bot, content };

        const { data } = await HttpService.post(endPoint, formatApiData);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IBotMessageResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getMessage = async (messageId: string) => {
    const response = { data: {} as IMessage, success: false, message: '' };

    try {
        const endPoint = `/messages/${messageId}`;
        const { data } = await HttpService.get(endPoint);
        const formatApiResponse = objToCamel(data) as IMessage;
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getMessages = async (leadId: string) => {
    const response = { data: [] as Array<IMessage>, success: false, message: '' };

    try {
        const endPoint = `/messages/${leadId}`;
        const { data } = await HttpService.get(endPoint);
        const formatApiResponse = data[0].messages.map((item: any) => objToCamel(item) as IMessage);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getMessagesByThread = async (threadId?: string) => {
    const response = { data: [] as Array<IMessage>, success: false, message: '' };

    try {
        const endPoint = `/messages/thread/${threadId}`;
        const { data } = await HttpService.get(endPoint);
        const formatApiResponse = data.map((item: any) => objToCamel(item) as IMessage);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
