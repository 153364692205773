import React from 'react';
import { Button, ButtonProps, Spinner } from 'reactstrap';

interface FormButtonProps {
    loading?: boolean;
    fitParent?: boolean;
}

export const FormButton: React.FC<FormButtonProps & ButtonProps> = ({
    loading,
    value,
    children,
    type = 'button',
    disabled,
    className,
    fitParent = true,
    onClick,
    ...rest
}) => {
    const customClass = (className || '') + (fitParent ? ' w-100' : '');

    return (
        <Button
            disabled={disabled || loading}
            type={type}
            onClick={onClick}
            className={customClass}
            color={rest.color ? rest.color : 'primary'}
        >
            {loading && (
                <Spinner size='sm' className='me-2'>
                    {' Loading...  '}
                </Spinner>
            )}
            {value || children}
        </Button>
    );
};
