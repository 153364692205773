import axios from 'axios';

let _header_token = '';

//Setting a base url for all axios requests
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
    (config) => {
        // If request already has an Authorization header, do nothing
        if (config.headers['Authorization']) return config;

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (result) => {
        return Promise.resolve(result);
    },
    (error) => {
        let message = 'Error: ';
        if (error.response?.data?.message) message += error.response.data.message;
        if (error.response?.data) {
            if (Array.isArray(error.response?.data)) {
                message += '<br />';
                error.response?.data.forEach((e: any, idx: number) => {
                    message +=
                        '<div class="d-flex align-items-center"><i class="ri-arrow-drop-right-line fs-20"></i>' +
                        e.msg +
                        (idx < error.response?.data.length - 1 ? '<br />' : '') +
                        '</div>';
                });
            } else message += error.response.data;
        } else message += error.message;

        return Promise.reject({ message });
    }
);

function setBaseUrl(url: string) {
    axios.defaults.baseURL = url;
}

function setJwt(jwt: string) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;
    _header_token = jwt;
}

function getJwt() {
    return _header_token;
}

export default {
    get: axios.get,
    post: axios.post,
    postForm: axios.postForm,
    put: axios.put,
    putForm: axios.putForm,
    patch: axios.patch,
    delete: axios.delete,
    setJwt,
    getJwt,
    setBaseUrl,
    axios
};
