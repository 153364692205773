import IThread from '@src/interfaces/IThread';
import HttpService from '@src/utils/HttpService';
import { objToCamel } from '@src/utils/helpers';

export const createThread = async (botId: string) => {
    const response = { data: {} as IThread, success: false, message: '' };

    try {
        const endPoint = '/threads/create/' + botId;
        const { data } = await HttpService.post(endPoint);
        console.log('data', data);
        response.data = objToCamel(data) as IThread;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getThreads = async () => {
    const response = { data: [] as Array<IThread>, success: false, message: '' };

    try {
        const endPoint = `/threads`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item) as IThread);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getThreadsByBot = async (botId: string) => {
    const response = { data: [] as Array<IThread>, success: false, message: '' };

    try {
        const endPoint = `/threads/byBot/${botId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item) as IThread);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};
