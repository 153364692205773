export enum RoutesName {
    //Public customers
    root = '/',
    accessDenied = '/access-denied',
    //Customers
    botEmbed = '/bot-embed/:id'
}

export enum MessageRole {
    assistant = 'assistant',
    user = 'user'
}

export enum MessageAction {
    validatePhoneNumber = 'ValidatePhoneNumber',
    createLead = 'CreateLead',
    endChat = 'EndChat',
    chatUnavailable = 'ChatUnavailable'
}
