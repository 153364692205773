import { create } from 'zustand';
import { IGlobalStore } from '@src/interfaces/IStore';
import { ConfirmationResult } from 'firebase/auth';

export default create<IGlobalStore>((set) => ({
    loading: false,
    phoneOTPResponse: {} as ConfirmationResult,
    setLoading: (val: boolean) => set(() => ({ loading: val })),
    setPhoneOTPResponse: (res: ConfirmationResult) => set(() => ({ phoneOTPResponse: res }))
}));
