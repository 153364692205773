//import Scss
import '@src/assets/scss/themes.scss';
import 'react-toastify/dist/ReactToastify.css';

// import MainRoutes from '@src/routes/MainRoutes'; comment
import { ToastContainer } from 'react-toastify';
import BotEmbed from '@src/pages/botEmbed';

export default () => (
    <>
        <BotEmbed />
        <ToastContainer />
    </>
);
