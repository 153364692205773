import _, { Dictionary, ObjectIterator } from 'lodash';
import { ToastContent, toast } from 'react-toastify';
import notificationSound from '@src/assets/sounds/notification-sound.mp3';
import IGooglePlace from '@src/interfaces/IGooglePlace';
import htmlParse from 'html-react-parser';
import CryptoJS from 'crypto-js';

export const formatString = function (...args: any[]) {
    let s = args[0];
    let i = args.length;

    while (i-- > 1) s = s.replace(new RegExp('\\{' + (i - 1) + '\\}', 'gm'), args[i]);
    return s;
};

export const waitNSeconds = (seconds: number) =>
    new Promise(async (resolve, reject) => {
        try {
            setTimeout(() => {
                return resolve({});
            }, seconds * 1000);
        } catch (error) {
            return reject(error);
        }
    });

export const sucessToast = (message: ToastContent, autoClose = 2000) => {
    const html = typeof message === 'string' ? htmlParse(`<div>${message}</div>`) : message;
    toast.success(html, {
        position: 'top-center',
        theme: 'colored',
        hideProgressBar: true,
        autoClose: autoClose
    });
};

export const errorToast = (message: ToastContent, autoClose = 2000) => {
    const html = typeof message === 'string' ? htmlParse(`<div>${message}</div>`) : message;
    toast.error(html, {
        position: 'top-center',
        theme: 'colored',
        hideProgressBar: true,
        autoClose: autoClose
    });
};

export const infoToast = (message: ToastContent, autoClose = 2000) => {
    const html = typeof message === 'string' ? htmlParse(`<div>${message}</div>`) : message;
    toast.info(html, {
        position: 'top-center',
        theme: 'colored',
        hideProgressBar: true,
        autoClose: autoClose
    });
};

export const generateColor = () => {
    return '#' + Math.random().toString(16).slice(-6);
};

// ***********************************************************************************************************************
// Set up to loop deep object with loadash
// ***********************************************************************************************************************

declare module 'lodash' {
    interface LoDashStatic {
        deeply<T extends object, TResult>(
            map: unknown
        ): (obj: T | null | undefined, callback: ObjectIterator<T, TResult>) => { [P in keyof T]: TResult };
    }
}

_.mixin({
    deeply: (map) => {
        return (object: Dictionary<unknown>, callback: ObjectIterator<object, unknown>) => {
            return map(
                _.mapValues(object, (value) => {
                    return _.isPlainObject(value) ? _.deeply(map)(value as object, callback) : value;
                }),
                callback
            );
        };
    }
});

export const isDictionary = (object: unknown): object is Record<keyof never, unknown> => {
    return object instanceof Object && object.constructor === Object;
};

export const mapKeysDeep = (
    object: Record<keyof never, unknown>,
    callback: (key: string, value: unknown) => keyof never
): Record<string, unknown> => {
    function iterate(value: unknown): unknown {
        if (isDictionary(value)) {
            return mapKeysDeep(value, callback);
        }

        if (value instanceof Array) {
            return value.map(iterate);
        }

        return value;
    }

    return Object.entries(object).reduce(
        (result, [key, value]) => ({ ...result, [callback(key, value)]: iterate(value) }),
        {}
    );
};

export const getChartColorsArray = (colors: Array<string>) => {
    return colors.map(function (value) {
        const newValue = value.replace(' ', '');
        if (newValue.indexOf(',') === -1) {
            let color = getComputedStyle(document.documentElement).getPropertyValue(newValue);

            if (color.indexOf('#') !== -1) color = color.replace(' ', '');
            if (color) return color;
            else return newValue;
        } else {
            const val = value.split(',');
            if (val.length === 2) {
                let rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                rgbaColor = 'rgba(' + rgbaColor + ',' + val[1] + ')';
                return rgbaColor;
            } else {
                return newValue;
            }
        }
    });
};

// ***********************************************************************************************************************
// End Set up to loop deep object with loadash
// ***********************************************************************************************************************

export const objToCamel = (data: any): any => {
    return mapKeysDeep(data, (key, value) => _.camelCase(key));
};

export const objToSnake = (data: any) => {
    return mapKeysDeep(data, (key, value) => _.snakeCase(key));
};

export const beep = () => {
    const sound = new Audio(notificationSound);
    sound.play();
};

export const capitalize = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const trimAll = (str: string) => str.trim().replaceAll(' ', '').replaceAll(' ', '');

export const splitAddress = (place: IGooglePlace) => {
    if (!place) return;
    let address = '';
    let city = '';
    let state = '';
    let zipcode = '';

    place.address_components.forEach((component) => {
        if (component.types.includes('street_number')) address = component.long_name;
        if (component.types.includes('route')) address += ' ' + component.long_name;
        if (component.types.includes('locality')) city = component.long_name;
        if (component.types.includes('administrative_area_level_1')) state = component.long_name;
        if (component.types.includes('postal_code')) zipcode = component.long_name;
    });

    return { fullAddress: place.formatted_address, address, city, state, zipcode };
};

export const convertToDDL = <T>(data: Array<T>, labelField: string, valueField: string) => {
    return data.map((item) => ({ label: item[labelField as keyof T], value: item[valueField as keyof T] }));
};

export const copyText = (text = '') => {
    navigator.clipboard.writeText(text);
    sucessToast('Code was copied to the clipboard!');
};

export const encryptData = (data: any) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_CRYPTO_KEY || '').toString();
};

export const decrypData = (data: any) => {
    if (!data) return '';
    try {
        const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_CRYPTO_KEY || '');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        return '';
    }
};

export const extractDomain = (url?: string) => {
    if (!url) return '';
    // Remove protocol
    let domain = url.replace(/^https?:\/\//i, '').replace(/^http?:\/\//i, '');
    // Remove www (optional)
    domain = domain.replace(/^www\./i, '');
    // Extract domain
    domain = domain.split('/')[0];

    return domain;
};
