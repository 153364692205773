import IBot from '@src/interfaces/IBot';
import { IBotAction } from '@src/interfaces/IBotMessageResponse';
import ILead from '@src/interfaces/ILead';
import IMessage from '@src/interfaces/IMessage';
import IThread from '@src/interfaces/IThread';
import { getBot } from '@src/services/BotService';
import { createMessage, getMessagesByThread } from '@src/services/MessageService';
import { createThread } from '@src/services/ThreadService';
import globalStore from '@src/stores/globalStore';
import { globals } from '@src/utils/constants';
import { MessageAction, MessageRole } from '@src/utils/enums';
import { beep, decrypData, encryptData, errorToast, extractDomain, objToCamel } from '@src/utils/helpers';
import _, { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

const useBotEmbed = () => {
    const loading = globalStore((state) => state.loading);
    const setLoading = globalStore((state) => state.setLoading);

    // connectionsBotID
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let botId = useRef(urlParams.get('botId'));
    if (botId.current && !botId.current?.includes('asst_')) botId.current = 'asst_' + botId.current;

    const messageAction = useRef<IBotAction>();

    const threadSession = decrypData(localStorage.getItem(globals.threadSessionKey));
    const leadToken = decrypData(localStorage.getItem(globals.leadSessionKey));

    const [bot, setBot] = useState<IBot>();
    const [errorMsg, setErrorMsg] = useState('');
    const [scroller, setScroller] = useState<HTMLElement | null>(null);
    const [messages, setMessages] = useState<Array<IMessage>>([]);
    const [currentMessageText, setCurrentMessageText] = useState('');
    const [waitingBotResponse, setWaitingBotResponse] = useState(false);
    const [lead, setLead] = useState<ILead>();
    const [thread, setThread] = useState<IThread>();
    const [coundown, setCountDown] = useState(-1);
    const [forceRefresh, setForceRefresh] = useState(false);

    const [accessDenied, setAccessDenied] = useState(false);
    const [minimized, setMinimized] = useState(true);
    const [maximize, setMaximize] = useState(false);
    const [isChatEmbeded] = useState(!!document.documentElement.getAttribute('chat-layout'));
    const [isChatOnIframe, setIsChatOnIframe] = useState(false);

    const startChat = async () => {
        setLoading(true);
        setErrorMsg('');
        const { data, success, message } = await createThread(botId.current!);
        if (success) {
            if (data) {
                localStorage.setItem(globals.threadSessionKey, encryptData(data));
                setThread(data);
                if (bot?.welcomeMessage) addMessageToChatUI(bot?.welcomeMessage, MessageRole.assistant);
                else
                    addMessageToChatUI(
                        `Hello, I'm <b>${bot?.name}</b>, how may I help you today?`,
                        MessageRole.assistant
                    );
                setTimeout(focusChatInput, 500);
            } else {
                setErrorMsg('Could not find token to connect');
            }
        } else {
            setErrorMsg(message);
        }
        setLoading(false);
    };

    const scrollToBottom = useCallback(() => {
        if (scroller) {
            scroller.scrollTop = scroller.scrollHeight + 1000;
        }
    }, [scroller]);

    const initData = async () => {
        setLoading(true);
        if (!botId.current) {
            botId.current = document.documentElement.getAttribute('botId');
            if (botId.current && !botId.current?.includes('asst_')) botId.current = 'asst_' + botId.current;
            console.log('connectionsBotID', botId);
            initData();
            return;
        }
        try {
            const { success, data, message } = await getBot(botId.current || '', true);

            if (success) {
                setBot(data);

                try {
                    setLead(leadToken);
                } catch (error) {}

                let threadDecoded = {} as IThread;

                try {
                    threadDecoded = objToCamel(threadSession) as IThread;
                    setThread(threadDecoded);
                    if (data.botId !== threadDecoded?.bot) {
                        closeChatSession();
                        setLoading(false);
                        return;
                    }
                } catch (error) {}

                if (threadSession) {
                    const messagesRes = await getMessagesByThread(threadDecoded?.threadId);
                    if (messagesRes.success) {
                        if (!messagesRes.data.length) {
                            if (data.welcomeMessage) addMessageToChatUI(data.welcomeMessage, MessageRole.assistant);
                            else
                                addMessageToChatUI(
                                    `Hello, I'm <b>${data?.name}</b>, how may I help you today?`,
                                    MessageRole.assistant
                                );
                        }

                        messagesRes.data.forEach((msj) =>
                            addMessageToChatUI(msj.content.text.value, msj.role, msj.createdAt)
                        );

                        setTimeout(focusChatInput, 10);
                    } else {
                        throw new Error(messagesRes.message);
                    }
                }
            } else {
                throw new Error(message);
            }
        } catch (error: any) {
            errorToast(error.message);
            setAccessDenied(true);
        }

        setLoading(false);
    };

    const onKeyPress = (e: any) => {
        const { key } = e;
        if (key === 'Enter') {
            e.preventDefault();
            handleMessage(currentMessageText);
        }
    };

    const handleMessage = async (message: string, role: MessageRole = MessageRole.user) => {
        if (!message) return;

        addMessageToChatUI(message, role);
        setCurrentMessageText('');
        setErrorMsg('');

        if (role == MessageRole.user) {
            setWaitingBotResponse(true);
            try {
                const { success, data } = await createMessage(thread!, message);

                if (success) {
                    addMessageToChatUI(data.answer.content.text.value, MessageRole.assistant);
                    await runActions(data.actions);
                } else {
                    setErrorMsg('Error sending messsage or getting response, please try again');
                }
            } catch (error: any) {
                setErrorMsg('Error sending messsage or getting response, please try again');
            }

            beep();
            setWaitingBotResponse(false);
            setTimeout(focusChatInput, 1000);
        }
    };

    const runActions = async (actions?: Array<IBotAction>) => {
        _.forEach(actions, async (action) => {
            messageAction.current = action;
            if (action.name === MessageAction.endChat || action.name === MessageAction.chatUnavailable)
                setCountDown(globals.waitTimeToCloseSession);

            if (action.name === MessageAction.createLead) {
                localStorage.setItem(globals.leadSessionKey, encryptData(action.value));
                setLead(action.value);
            }
        });
    };

    const addMessageToChatUI = async (message: string, role: MessageRole, createdAt: Date = new Date()) => {
        if (!message) return;
        try {
            const msjObj: IMessage = {
                messageId: Date.now().toString(),
                content: { type: 'text', text: { value: message } },
                role,
                createdAt
            };
            //To update message and state in the same call
            messages.push(msjObj);
            setMessages([...messages]);
        } catch (error: any) {
            console.error(error.message);
        }
    };

    const focusChatInput = () => {
        const chatInput = document.querySelector('#chat-input') as HTMLInputElement;
        console.log('chatInput', chatInput);
        // alert(chatInput);
        if (chatInput) chatInput.focus();
    };

    const closeChatSession = () => {
        localStorage.removeItem(globals.threadSessionKey);
        localStorage.removeItem(globals.leadSessionKey);
        setThread(undefined);
        setLead(undefined);
        setErrorMsg('');
        setMessages([]);
    };

    const resendLastMessage = () => {
        const userMessages = messages.filter((msj) => msj.role === MessageRole.user);
        if (userMessages?.length) {
            handleMessage(userMessages[userMessages.length - 1].content.text.value);
        }
    };

    useEffect(() => {
        console.log('window.frameElement', window.frameElement);
        try {
            if (window.frameElement) setIsChatOnIframe(true);
            else if (window.parent.location.href !== document.location.href) setIsChatOnIframe(true);
        } catch (error) {
            setIsChatOnIframe(true);
        }
        initData();
    }, []);

    useEffect(() => {
        if (!isEmpty(messages)) scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (forceRefresh) setForceRefresh(false);
    }, [forceRefresh]);

    useEffect(() => {
        console.log('isChatOnIframe', isChatOnIframe);
        if (bot?.allowedDomain && isChatOnIframe) {
            setAccessDenied(true);
            closeChatSession();
        } else if (
            bot?.allowedDomain &&
            isChatEmbeded &&
            extractDomain(bot?.allowedDomain) !== extractDomain(document.location.href)
        ) {
            setAccessDenied(true);
            closeChatSession();
        } else setAccessDenied(false);
    }, [bot, isChatOnIframe]);

    useEffect(() => {
        if (coundown >= 0) {
            setWaitingBotResponse(true);
            setTimeout(() => {
                if (coundown <= 0) {
                    //If chat unavailable, we request the chat info again to refresh the bot session and show the unavailable message
                    if (messageAction.current?.name === MessageAction.chatUnavailable) initData();
                    else closeChatSession(); //If EndChat action, then, we close the session
                }

                setCountDown(coundown - 1);
            }, 1000);
        } else setWaitingBotResponse(false);
    }, [coundown]);

    useEffect(() => {
        if (minimized) {
            document.documentElement.setAttribute('chat-is-minized', 'true');
            document.documentElement.setAttribute('chat-is-maximize', 'false');
        } else {
            document.documentElement.setAttribute('chat-is-minized', 'false');
            if (maximize) document.documentElement.setAttribute('chat-is-maximize', 'true');
        }
    }, [minimized]);

    useEffect(() => {
        if (maximize) {
            document.documentElement.setAttribute('chat-is-maximize', 'true');
            if (isChatEmbeded) document.documentElement.setAttribute('chat-layout', '');
        } else {
            document.documentElement.setAttribute('chat-is-maximize', 'false');
            if (isChatEmbeded) document.documentElement.setAttribute('chat-layout', 'embed');
        }
    }, [isChatEmbeded, maximize]);

    useEffect(() => {
        setMinimized(isChatEmbeded);
    }, [isChatEmbeded]);

    return {
        thread,
        lead,
        bot,
        errorMsg,
        loading,
        messages,
        waitingBotResponse,
        currentMessageText,
        coundown,
        accessDenied,
        minimized,
        maximize,
        isChatEmbeded,
        setMinimized,
        setMaximize,
        closeChatSession,
        setScroller,
        onKeyPress,
        setCurrentMessageText,
        handleMessage,
        startChat,
        resendLastMessage
    };
};

export default useBotEmbed;
