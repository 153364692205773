import Lottie from 'lottie-react';
import React from 'react';
import animation1 from '@src/assets/animations/connections-loading1.json';
import animation2 from '@src/assets/animations/connections-loading2.json';

interface ILoader {
    show?: boolean;
}

const Loader: React.FC<ILoader> = ({ show }) => {
    if (!show) return null;
    return (
        <div
            className='d-flex justify-content-center align-items-center'
            style={{ position: 'absolute', inset: 0, backgroundColor: '#000000aa', zIndex: 10 }}
        >
            <div style={{ width: '20vh', height: '20vh' }}>
                <Lottie
                    animationData={animation1}
                    loop={true}
                    className=''
                    style={{ position: 'absolute', width: '20vh', height: '20vh' }}
                />
                <Lottie
                    animationData={animation2}
                    loop={true}
                    className=''
                    style={{ position: 'absolute', width: '20vh', height: '20vh' }}
                />
            </div>
        </div>
    );
};

export const LoaderChat: React.FC<ILoader> = ({ show }) => {
    if (!show) return null;

    return (
        <div
            style={{
                width: '100%',
                height: '70.5px',
                position: 'absolute',
                backgroundColor: '#000000aa',
                zIndex: 10,
                bottom: 0
            }}
        >
            <Lottie
                animationData={animation2}
                loop={true}
                style={{
                    position: 'absolute',
                    width: '70px',
                    height: '70px',
                    left: '50%',
                    marginLeft: '-35px'
                }}
            />
        </div>
    );
};

export default Loader;
