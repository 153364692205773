import IBot from '@src/interfaces/IBot';
import { IObj } from '@src/interfaces/IGeneric';
import HttpService from '@src/utils/HttpService';
import { objToCamel, objToSnake } from '@src/utils/helpers';

export const createBot = async (bot: IBot) => {
    const response = { data: {} as IBot, success: false, message: '' };

    try {
        const endPoint = '/bots/create';
        console.log('before snake');
        const formatApiData = objToSnake(bot) as IObj;
        console.log('after snake', formatApiData);
        if (formatApiData.meta && !Object.keys(formatApiData.meta).length) delete formatApiData.meta;
        console.log('data sent', formatApiData);

        if (!bot.allowedDomain) delete formatApiData.allowed_domain;
        const formData = objToFormData(formatApiData);

        console.log('formData', formData);

        const { data } = await HttpService.post(endPoint, formData);

        console.log('data', data);
        const formatApiResponse = objToCamel(data);
        console.log('formatApiResponse', formatApiResponse);
        response.data = formatApiResponse as IBot;
        response.success = true;
    } catch (error: any) {
        console.error(error);
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBot = async (botId: string, fromChat?: boolean) => {
    const response = { data: {} as IBot, success: false, message: '' };

    try {
        let endPoint = `/bots/${botId}`;
        if (fromChat) endPoint += '?fromChat=true';

        const { data } = await HttpService.get(endPoint);
        const formatApiResponse = objToCamel(data) as IBot;
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBots = async () => {
    const response = { data: [] as Array<IBot>, success: false, message: '' };

    try {
        const endPoint = `/bots`;

        const { data } = await HttpService.get(endPoint);
        console.log('data', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item)) as Array<IBot>;
        console.log('formatBotsApiResponse', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const getBotsByUser = async (userId = '') => {
    const response = { data: [] as Array<IBot>, success: false, message: '' };

    try {
        const endPoint = `/bots/getByUser/${userId}`;

        const { data } = await HttpService.get(endPoint);
        console.log('data getBotsByUser', data);
        const formatApiResponse = data.map((item: any) => objToCamel(item)) as Array<IBot>;
        console.log('formatBotsApiResponse getBotsByUser', formatApiResponse);
        response.data = formatApiResponse;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

export const deleteBot = async (botId: string) => {
    const response = { data: {} as IBot, success: false, message: '' };

    try {
        const endPoint = `/bots/${botId}`;
        const { data } = await HttpService.delete(endPoint);
        const formatApiResponse = objToCamel(data);
        response.data = formatApiResponse as IBot;
        response.success = true;
    } catch (error: any) {
        response.message = error.message || 'Unable to connect to the api';
    }

    return response;
};

const objToFormData = (objData: IObj) => {
    const formData = new FormData();
    Object.entries(objData).forEach(([key, value]) => {
        if (key == 'documents' || key == 'avatar') return; //This only are the urls when is already uploaded

        if (Array.isArray(value)) {
            value?.forEach((item: any) => formData.append(key.replace('_inputs', '').replace('_input', ''), item));
        } else if (key == 'meta') {
            formData.append(key, JSON.stringify(value));
        } else formData.append(key.replace('_inputs', '').replace('_input', ''), value);
    });

    return formData;
};
