import { Col, Row } from 'reactstrap';

import error from '@src/assets/images/error-unavailable.svg';

const Unavailable = () => {
    return (
        <>
            <Row>
                <Col lg={12}>
                    <div className='text-center pt-4'>
                        <div className=''>
                            <img src={error} alt='' className='error-basic-img' />
                        </div>
                        <div>
                            <h3 className='text-uppercase'>Unavailable</h3>
                            <p className='text-muted mb-4'>The page you are looking for is not currently accessible.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Unavailable;
