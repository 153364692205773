import { Alert, Badge, Card, CardBody, Col, Row } from 'reactstrap';
// import { Link } from 'react-router-dom';
import htmlParse from 'html-react-parser';

import chatImage from '@src/assets/images/chat-img.png';
import userDummayImage from '@src/assets/images/users/user-dummy-img.jpg';

import chatBubleAnimation from '@src/assets/animations/chat-bubble.json';
import { FormButton } from '@src/components/FormButton';
import Loader, { LoaderChat } from '@src/components/Loader';
import PlaceHolder from '@src/components/PlaceHolder';
import PublicLayout from '@src/partials/PublicLayout';
import { globals } from '@src/utils/constants';
import { MessageRole } from '@src/utils/enums';
import Lottie from 'lottie-react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import AccessDenied from '../app/AccessDenied';
import Unavailable from '../app/Unavailable';
import useBotEmbed from './useBotEmbed';

const BotEmbed = () => {
    const {
        thread,
        lead,
        bot,
        errorMsg,
        loading,
        messages,
        waitingBotResponse,
        currentMessageText,
        coundown,
        accessDenied,
        minimized,
        maximize,
        isChatEmbeded,
        setMinimized,
        setMaximize,
        closeChatSession,
        setScroller,
        onKeyPress,
        setCurrentMessageText,
        handleMessage,
        startChat,
        resendLastMessage
    } = useBotEmbed();

    if (!isChatEmbeded) document.title = 'Assistant | Connections';

    if (isChatEmbeded && minimized)
        return (
            <div
                className='flex-shrink-0 chat-user-img online user-own-img align-self-center'
                role='button'
                onClick={() => setMinimized(false)}
            >
                <img
                    src={bot?.avatar || chatImage}
                    className='img-thumbnail rounded-circle avatar-sm'
                    alt='chat'
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = chatImage;
                    }}
                />
            </div>
        );

    if ((!bot?.isSubscriptionActive || bot.temporaryDisabled) && !loading)
        return (
            <div>
                {isChatEmbeded && (
                    <div className='w-100 d-flex justify-content-end pe-2 position-absolute z-1'>
                        <span
                            className='mdi mdi-window-minimize fs-2 text-primary'
                            role='button'
                            onClick={() => setMinimized(true)}
                        />
                    </div>
                )}
                <PublicLayout children={<Unavailable />} />
            </div>
        );

    if (accessDenied)
        return (
            <div>
                {isChatEmbeded && (
                    <div className='w-100 d-flex justify-content-end pe-2 position-absolute z-1'>
                        <span
                            className='mdi mdi-window-minimize fs-2 text-primary'
                            role='button'
                            onClick={() => setMinimized(true)}
                        />
                    </div>
                )}
                <PublicLayout children={<AccessDenied />} />
            </div>
        );

    if (!thread) {
        return (
            <>
                <Loader show={loading} />
                {isChatEmbeded && (
                    <div className='w-100 d-flex justify-content-end pe-2'>
                        <span
                            className='mdi mdi-window-minimize fs-2 text-primary'
                            role='button'
                            onClick={() => setMinimized(true)}
                        />
                    </div>
                )}
                {loading && <PlaceHolder />}
                {!loading && (
                    <Card className='m-4'>
                        <CardBody className='p-4'>
                            <div className='text-center mt-2'>
                                <h5 className='text-primary'>Welcome!</h5>
                                <p className='text-muted'>
                                    {bot?.description
                                        ? bot?.description
                                        : `I'm <b>${bot?.name}</b> and I'll be your assistant.`}
                                </p>
                            </div>
                            {!!errorMsg && <Alert color='danger'> {errorMsg} </Alert>}
                            {!!bot?.startImage && (
                                <div className='text-center' style={{ height: '250px' }}>
                                    <img
                                        src={bot?.startImage}
                                        style={{
                                            borderRadius: 15,
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            width: '500px',
                                            height: '100%'
                                        }}
                                    />
                                </div>
                            )}
                            <div className='p-2 text-center'>
                                <div className='mb-4 mt-4'>
                                    <p className='mb-0 fs-12 text-muted fst-italic'>
                                        By clicking Continue you agree to Connections{' '}
                                        <a
                                            href={globals.termsUrl}
                                            className='text-primary text-decoration-underline fst-normal fw-medium'
                                        >
                                            Terms of Use{' '}
                                        </a>
                                        and{' '}
                                        <a
                                            href={globals.privacyUrl}
                                            className='text-primary text-decoration-underline fst-normal fw-medium'
                                        >
                                            Privacy Policy
                                        </a>
                                    </p>
                                </div>

                                <div className='mt-4'>
                                    <FormButton
                                        value='Continue'
                                        type='button'
                                        fitParent={false}
                                        disabled={loading}
                                        loading={loading}
                                        onClick={startChat}
                                        style={{ maxWidth: 200 }}
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                )}
            </>
        );
    }

    return (
        <div className='chat-wrapper gap-1'>
            <Loader show={loading} />
            {coundown >= 0 && (
                <div className='countdown'>
                    <div className='outer-circle'>
                        <div className='avatar-md'>
                            <div className={'avatar-title rounded-circle fs-24 bg-danger'}>{coundown}</div>
                        </div>
                    </div>
                    <Badge pill color='primary' className='fs-15 mt-2'>
                        Closing chat
                    </Badge>
                </div>
            )}
            {/* <div className='user-chat w-100 overflow-hidden'> */}
            <div className='user-chat chat-content' style={{ display: 'flex', flexDirection: 'column' }}>
                <div className='p-3 user-chat-topbar chat-bar' style={{ position: 'relative' }}>
                    <Row className='align-items-center'>
                        <Col xs={12}>
                            <div className='d-flex align-items-center'>
                                <div className='flex-grow-1 overflow-hidden'>
                                    <div className='d-flex align-items-center'>
                                        <div className='flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0'>
                                            <img
                                                src={bot?.avatar || userDummayImage}
                                                className='img-thumbnail rounded-circle avatar-xs'
                                                alt='avatar'
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = userDummayImage;
                                                }}
                                            />
                                        </div>
                                        <div className='flex-grow-1 overflow-hidden'>
                                            <h5 className='text-truncate mb-0 fs-16'>{bot?.name}</h5>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <i
                                                className='ri-logout-box-line align-bottom fs-3 text-danger'
                                                role='button'
                                                onClick={closeChatSession}
                                            ></i>
                                            {isChatEmbeded && (
                                                <>
                                                    <span
                                                        className='mdi mdi-window-minimize fs-2 ms-2 text-primary'
                                                        role='button'
                                                        onClick={() => setMinimized(true)}
                                                    />
                                                    {!maximize && (
                                                        <span
                                                            className='mdi mdi-arrow-expand fs-3 ms-2 text-primary'
                                                            role='button'
                                                            onClick={() => setMaximize(true)}
                                                        />
                                                    )}
                                                    {maximize && (
                                                        <span
                                                            className='mdi mdi-arrow-collapse fs-3 ms-2 text-primary'
                                                            role='button'
                                                            onClick={() => setMaximize(false)}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='position-relative' id='users-chat' style={{ flex: 1 }}>
                    <div className='chat-conversation chat-conversation-no-reply px-3' id='chat-conversation'>
                        <PerfectScrollbar containerRef={setScroller} className='pb-4'>
                            <ul className='list-unstyled chat-conversation-list' id='users-conversation'>
                                {messages.map((message, key) => (
                                    <li
                                        className={
                                            message.role === MessageRole.user ? ' chat-list left' : 'chat-list right'
                                        }
                                        key={key}
                                    >
                                        <div className='conversation-list'>
                                            <div className='user-chat-content'>
                                                <div className='ctext-wrap align-items-center'>
                                                    {message.role == MessageRole.user && (
                                                        <div className='me-2'>
                                                            <div className='avatar-title rounded-circle avatar-xxs'>
                                                                {lead?.name?.charAt(0) || '?'}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className='ctext-wrap-content'>
                                                        <p className='mb-0 ctext-content'>
                                                            {message.role === MessageRole.assistant
                                                                ? htmlParse(message.content.text.value)
                                                                : message.content.text.value}
                                                        </p>
                                                    </div>
                                                    {message.role === MessageRole.assistant && (
                                                        <div className='chat-avatar'>
                                                            <img
                                                                src={bot?.avatar}
                                                                alt=''
                                                                onError={({ currentTarget }) => {
                                                                    currentTarget.onerror = null; // prevents looping
                                                                    currentTarget.src = userDummayImage;
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                    {message.role === MessageRole.user && (
                                                        <i
                                                            className='resend-message ri-send-plane-2-fill fs-24 ms-2 text-primary'
                                                            role='button'
                                                            onClick={() => handleMessage(message.content.text.value)}
                                                        />
                                                    )}
                                                </div>
                                                <div
                                                    className='conversation-name'
                                                    style={
                                                        message.role === MessageRole.assistant
                                                            ? { marginRight: 45 }
                                                            : { marginLeft: 35 }
                                                    }
                                                >
                                                    <small className='text-muted time'>
                                                        {moment(message.createdAt).format('HH:mm A')}
                                                    </small>{' '}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {!!errorMsg && (
                                    <li className='d-flex justify-content-end align-items-center  me-4'>
                                        <Alert className='m-0' color='danger'>
                                            {errorMsg}
                                        </Alert>
                                        <i
                                            className='mdi mdi-refresh fs-24 ms-2'
                                            role='button'
                                            onClick={resendLastMessage}
                                        />
                                    </li>
                                )}
                                {waitingBotResponse && coundown < 0 && (
                                    <li className='d-flex justify-content-end'>
                                        <div id='chat-buble-animation-container'>
                                            <Lottie
                                                animationData={chatBubleAnimation}
                                                loop={true}
                                                className=''
                                                style={{ transform: 'scaleX(-1)' }}
                                            />
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </PerfectScrollbar>
                    </div>
                </div>
                <LoaderChat show={waitingBotResponse} />
                <div className='chat-input-section p-3 chat-bar'>
                    <Row className='g-0 align-items-center'>
                        <div className='col'>
                            <div className='chat-input-feedback'>Please Enter a Message</div>
                            <textarea
                                id='chat-input'
                                value={currentMessageText}
                                onKeyDown={onKeyPress}
                                onChange={(e) => setCurrentMessageText(e.target.value)}
                                className='form-control chat-input bg-light border-light'
                                placeholder='Type your message...'
                                disabled={waitingBotResponse}
                            />
                        </div>
                        <div className='col-auto'>
                            <div className='chat-input-links ms-2'>
                                <div className='links-list-item'>
                                    <div
                                        onClick={() => handleMessage(currentMessageText)}
                                        role='button'
                                        className='chat-send waves-effect waves-light btn-primary ps-2 pe-2'
                                    >
                                        <i className='ri-send-plane-2-fill text-primary fs-24 align-bottom'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
};

export default BotEmbed;
